import './App.css';
import './glitch.css';


function App() {
  return (
    <div className="wrapper">
      <h1 className="glitch" >hi.</h1>
    </div>
  );
}

export default App;
